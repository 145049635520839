
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ExampleBox, Muted } from '../Components'
import wholeImg from './whole-screenshot.png'
import { Box } from '@mui/material'
import { Head } from '../Head'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head title="Что такое Zerro?" description="Zerro помогает вести личный и семейный бюджет." mdxType="Head" />
    <h1>{`👋 Привет!`}</h1>
    <p>{`Zerro — это неофициальное приложение для `}<a parentName="p" {...{
        "href": "https://zenmoney.ru/"
      }}>{`Дзен-мани`}</a>{`, оно помогает правильно планировать деньги.`}</p>
    <ExampleBox symbol="🖤" mdxType="ExampleBox">
  Zerro — бесплатное приложение, но вы всегда можете{' '}
  <a href="/donation">купить мне кофе</a>.
  <br />
  <Muted mdxType="Muted">Обязательно напишите, что вам понравилось</Muted>
    </ExampleBox>
    <Box component="img" src={wholeImg} alt="Примерно так выглядит интерфейс внутри" sx={{
      maxWidth: '100%',
      borderRadius: 1,
      border: '1px solid rgba(0,0,0,0.08)',
      boxShadow: '0 8px 32px 0 rgba(0,0,0,0.16)'
    }} mdxType="Box" />
    <h2>{`Что внутри?`}</h2>
    <h3>{`Бюджеты`}</h3>
    <p>{`Основное достоинство Zerro — бюджеты по типу конвертов. Если вы пользовались YNAB, знаете, как это круто. Прочитайте о подходе к бюджетам, чтобы лучше разобраться что к чему.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about/method"
        }}>{`Подход к бюджетам в Zerro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about/quick-start"
        }}>{`Начало работы`}</a></li>
    </ul>
    <h3>{`Исправление корректировок`}</h3>
    <p>{`Иногда после синхронизации с банком появляются дубли операций, чтобы их отловить:`}</p>
    <ol>
      <li parentName="ol">{`Откройте операцию-дубль`}</li>
      <li parentName="ol">{`Нажмите внизу «Другие из этой синхронизации»`}</li>
      <li parentName="ol">{`Теперь можете просмотреть эти операции и удалить, если потребуется`}</li>
    </ol>
    <h3>{`И ещё...`}</h3>
    <ul>
      <li parentName="ul">{`Восстановление удалённых операций`}</li>
      <li parentName="ul">{`Тёмная тема 🌚`}</li>
      <li parentName="ul">{`Групповые действия с операциями: смена категории и удаление.`}</li>
      <li parentName="ul">{`Полный бэкап всех данных (пока без восстановления)`}</li>
    </ul>
    <h2>{`Как установить приложение?`}</h2>
    <p>{`Zerro — веб-приложение. Оно такое же классное, как и обычные приложения (может даже работать без сети), а устанавливается прямо из браузера ✨`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Айфон.`}</strong>{` В Safari «Поделиться» → «На главный экран»`}</li>
      <li parentName="ul"><strong parentName="li">{`Андроид.`}</strong>{` В Chrome Три точки в верхнем правом углу → «Установить приложение»`}</li>
      <li parentName="ul"><strong parentName="li">{`Windows / MacOS.`}</strong>{` В Chrome иконка в адресной строке «Установить приложение»`}</li>
    </ul>
    <h2>{`Пара слов о безопасности`}</h2>
    <ExampleBox symbol="🔒" mdxType="ExampleBox">
  У Zerro нет доступа к банковским подключениям. Дзен-мани хранят эти данные у
  вас в приложении и не передаёт через API, у сторонних сервисов просто нет
  доступа к ним.
  <br />
  <a href="https://github.com/zenmoney/ZenPlugins/wiki/ZenMoney-API">
    Документация API
  </a>
    </ExampleBox>
    <ul>
      <li parentName="ul">{`Zerro ничего не хранит у себя. Данные хранятся локально на вашем компьютере и в Дзен-мани.`}</li>
      <li parentName="ul">{`Исходный код открыт и `}<a parentName="li" {...{
          "href": "https://github.com/ardov/zerro"
        }}>{`лежит на GitHub`}</a>{`.`}</li>
      <li parentName="ul">{`Zerro собирает только анонимную статистику типа количества посещений, эти цифры греют мне душу и помогают решать проблемы 🙂`}</li>
    </ul>
    <h2>{`Полезные ссылки`}</h2>
    <ExampleBox symbol="💵" mdxType="ExampleBox">
  <a href="/about/method">Подход к бюджетам в Zerro</a> →
  <br />
  <Muted mdxType="Muted">Как пользоваться бюджетами</Muted>
    </ExampleBox>
    <ExampleBox symbol="🚀" mdxType="ExampleBox">
  <a href="/about/quick-start">Начало работы с Zerro</a> →
  <br />
  <Muted mdxType="Muted">Эта инструкция поможет решить проблемы на старте</Muted>
    </ExampleBox>
    <ExampleBox symbol="🔥" mdxType="ExampleBox">
  <a href="https://t.me/zerroapp">Канал с обновлениями в телеграме @zerroapp</a>
   →
  <br />
  <Muted mdxType="Muted">Обновления и фишки</Muted>
    </ExampleBox>
    <ExampleBox symbol="💬" mdxType="ExampleBox">
  <a href="https://t.me/zerrochat">Чат в телеграме @zerrochat</a> →
  <br />
  <Muted mdxType="Muted">
    Задавайте вопросы тут, или пишите <a href="http://t.me/ardov">мне</a>{' '}
    напрямую
  </Muted>
    </ExampleBox>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;