
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { ExampleBox, DetailsBox, TextLink } from '../Components'
import { Box } from '@mui/material'
import wholeImg from './whole-screenshot.png'
import { Head } from '../Head'


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head title="Начало работы | Zerro" description="Инструкция поможет начать вести бюджет" mdxType="Head" />
    <h1>{`Начало работы`}</h1>
    <h2>{`1. Настраиваем счета`}</h2>
    <p>{`Для начала давайте определимся какие деньги будем делить по категориям. Есть две группы счетов:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Счета в балансе.`}</strong>{` Это те деньги, с которыми мы будем работать и распределять по категориям.`}</li>
      <li parentName="ul"><strong parentName="li">{`Счета за балансом.`}</strong>{` Эти счета не учитываются в бюджете и никак на него не влияют. Сюда стоит отнести все долговые счета: кредиты, ипотеки. А также деньги которые мы не хотим распределять по категориям, например, инвестиционные счета.`}</li>
    </ul>
    <p>{`Включить или выключить счёт из баланса можно прямо в Zerro, достаточно дважды кликнуть по нему.`}</p>
    <DetailsBox title="Что делать с кредитками?" mdxType="DetailsBox">
  Кредитками можно пользоваться по-разному, поэтому они могут попасть и в одну и в другую группу. Как вы используете кредитку?
  <ul>
    <li>
      <strong>Не использую кредитку, только гашу долг.</strong> Значит можно
      считать её кредитом и вынести за баланс.
    </li>
    <li>
      <strong>
        Я пользуюсь кредиткой и у меня всегда есть деньги чтобы погасить долг по
        ней.
      </strong>{' '}
      В этом случае кредитку можно смело включать в баланс.
    </li>
    <li>
      <strong>Я пользуюсь кредиткой и на ней большой долг.</strong> К сожалению,
      сейчас у Зерро нет красивого решения такой ситуации. Но можно использовать
      пару хитростей.
    </li>
  </ul>
    </DetailsBox>
    <h2>{`2. Сбрасываем остатки`}</h2>
    <ExampleBox symbol="⚠️" mdxType="ExampleBox">
  Меняя бюджеты в Zerro, вы меняете бюджеты в Дзен-мани и наоборот. В этом нет
  ничего страшного, просто это важно понимать 😉
    </ExampleBox>
    <p>{`Если вы пользовались бюджетами Дзен-мани, в категориях могут быть неправильные остатки. Чтобы их сбросить, нажмите на кнопку «Сбросить остатки» в правой панели — Zerro удалит все будущие бюджеты и скорректирует бюджеты в прошлом месяце так, чтобы остатки не переносились.`}</p>
    <DetailsBox title="Почему остатки были неправильными?" mt={5} mdxType="DetailsBox">
  В Дзен-мани остаток сгорает в конце месяца, а в Zerro наоборот — бережно
  переносится из месяца в месяц. Из-за этого в некоторых категориях могли
  накопиться большие суммы.
    </DetailsBox>
    <h2>{`3. Распределяем деньги`}</h2>
    <p>{`Ура, мы готовы распределять деньги! Откройте текущий месяц и посмотрите на цифру «Не распределено» — именно столько денег нужно распределить по категориям (конвертам). Если какой-то категории не хватает, создайте её в Дзен-мани.`}</p>
    <Box component="img" src={wholeImg} alt="Примерно так выглядит интерфейс внутри" sx={{
      my: 4,
      maxWidth: '100%',
      borderRadius: 1,
      border: '1px solid rgba(0,0,0,0.08)',
      boxShadow: '0 8px 32px 0 rgba(0,0,0,0.16)'
    }} mdxType="Box" />
    <h3>{`Как копить?`}</h3>
    <p>{`А как бы мы копили с бумажными конвертами? Завели бы отдельный конверт под каждую цель: отпуск, автомобиль, новый телефон и так далее. Работайте с Zerro точно так же. Создайте нужную категорию и регулярно откладывайте туда деньги.`}</p>
    <DetailsBox title="Как скрыть категорию в Дзен-мани, но оставить в Zerro?" mdxType="DetailsBox">
  <ol>
    <li> Создайте категорию и укажите «Показывать в расходе»</li>
    <li> Задайте ей бюджет</li>
    <li> Снимите галочку «Показывать в расходе»</li>
  </ol>
  Теперь категория не появится в расходных категориях Дзен-мани, но будет видна в Zerro,
  потому что в этом «конверте» есть деньги.
    </DetailsBox>
    <h3>{`Как учитывать переводы?`}</h3>
    <p>{`Все переводы за баланс забирают деньги из бюджета. Например переводы на ипотеку или инвестиционный счёт. Такие переводы хорошо бы закладывать в бюджет чтобы планировать заранее и Zerro умеет это делать.`}</p>
    <p>{`Например каждый месяц мы платим 1000₽ по кредиту, для этого мы переводим деньги на специальный счёт за балансом. Как учитывать этот перевод?`}</p>
    <ol>
      <li parentName="ol">{`Создаём категорию «Погашение кредита» и откладываем туда 1000₽. Так мы резервируем деньги на перевод.`}</li>
      <li parentName="ol">{`Открываем эту категорию в Zerro и привязываем к ней наш счёт. Теперь все переводы на этот счёт будут учитываться как расходы по категории «Погашение кредита».`}</li>
      <li parentName="ol">{`Когда мы переведём 1000₽ на кредит, перевод запишется как расход в категории.`}</li>
    </ol>
    <h2>{`4. Следим за бюджетом`}</h2>
    <ul>
      <li parentName="ul">{`После каждой зарплаты заходите в Zerro и раскладывайте нераспределённые деньги по категориям.`}</li>
      <li parentName="ul">{`Периодически проверяйте, что в категориях достаточно денег. Для этого Zerro можно `}<a parentName="li" {...{
          "href": "https://t.me/zerroapp/10"
        }}>{`установить, как приложение`}</a>{`.`}</li>
      <li parentName="ul">{`Перемещайте деньги из одной категории в другую если нужно. Их можно прямо перетаскивать из колонки «Доступно».`}</li>
      <li parentName="ul">{`Копите и `}<a parentName="li" {...{
          "href": "https://t.me/zerroapp/7"
        }}>{`создавайте новые цели`}</a>{`.`}</li>
      <li parentName="ul">{`Подписывайтесь на `}<a parentName="li" {...{
          "href": "https://t.me/zerroapp"
        }}>{`канал с обновлениями`}</a>{` и задавайте вопросы в `}<a parentName="li" {...{
          "href": "https://t.me/zerrochat"
        }}>{`чате`}</a>{`.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;